import _ from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import writeXlsxFile from 'write-excel-file'

export const useDonsExportExcel = (fileName: string) => {

    const schema = [
        {
            column: 'Transaction',
            type: String,
            value: (don: any) => don.refId,
            length: 600
        },
        {
            column: 'Status',
            type: String,
            value: (don: any) => statusString(don.status)
        },
        {
            column: 'Date',
            type: String,
            value: (don: any) => moment(don.createdAt).format("DD/MM/yyyy")
        },
        {
            column: 'Montant',
            type: String,
            value: (don: any) => don.amount
        },
        {
            column: 'Association',
            type: String,
            value: (don: any) => don._association.firstName
        },
        {
            column: 'Donateur',
            type: String,
            value: (don: any) => `${don._donator?.firstName} ${don._donator?.lastName}`
        },
        {
            column: 'Email',
            type: String,
            value: (don: any) => don._donator?.email
        },
        {
            column: 'Age',
            type: Number,
            value: (don: any) => {
                const value = moment().diff(moment(don._donator?.dob, 'DD/MM/yyyy'), 'years')
                return _.isNaN(value) ? 0 : value
            }
        },
        {
            column: 'Numéro de téléphone',
            type: String,
            value: (don: any) => don._donator?.phone
        },
        {
            column: 'Nationalité',
            type: String,
            value: (don: any) => don._donator?.nationality
        },
        {
            column: 'Address',
            type: String,
            value: (don: any) => don._donator?.address?.address
        },
        {
            column: 'IP',
            type: String,
            value: (don: any) => don._donator?.ipAddress
        },
    ]

    return useCallback((data: any) => {
        return writeXlsxFile(data, {
            schema: schema,
            fileName: `${fileName}.xlsx`
        })
    }, [fileName])
}

const statusString = (status: number) => {
    if (status == 0) return 'En attente'
    if (status == 1) return 'Succès'
    if (status == 2) return 'Echec'
    if (status == 3) return 'Remboursée'
    return;
}